
* {
   
    box-sizing: border-box;
}

.wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.input-box {
    position: relative;
    width: 100%;
    height: 50px;
    padding-bottom: 10px;   
}

.input-box input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
    color: blue;
}

.input-box .icon {
    align-items: center;
    position: absolute;
    right: -0px;
    top: 20%;
    transform: translate(-60%);
    font-size: 20x;
}

.wrapper Button {
    width: 100%;
    height: 40px;
}
