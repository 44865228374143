.steps {
    display: flex; 
    gap: 4rem;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 2rem;
}

.step {
    text-align: center;
    background-color: #fff;
    z-index: 1;
    width: 120px;
    padding: 0.5rem;
    opacity: 0.3;
}

.step svg {
    font-size: 1.6rem;
    margin-bottom: .2rem;
}

.active {
    font-weight: bold;
    opacity: 1.0;
}

.active > svg {
    fill: #00a8ff;
}