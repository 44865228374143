.form-container {
    max-width: 1100px;
    margin: 0 auto;
    background-color: #fff;
    padding: 1.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
.form-control {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 2rem;
  text-align: left;
}

.form-control-checkbox {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  margin-bottom: 2rem;
}

label {
  font-weight: bold;
  font-size: 1rem;
}

input,
textarea {
  padding: .6rem;
  border-radius: 3px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1;
}